export const SCALABILITY = 'scalability';
export const REUSABILITY = 'reusability';
export const MULTIPLICATIVE_EFFECT = 'multiplicative_effect';
export const TIME_SENSITIVITY = 'time_sensitivity';
export const STRATEGIC_IMPORTANCE = 'strategic_importance';
export const LABOR_LEVERAGE = 'labor_leverage';
export const CAPITAL_LEVERAGE = 'capital_leverage';
export const TECHNOLOGY_LEVERAGE = 'technology_leverage';
export const SOCIAL_CONNECTIONS_LEVERAGE = 'social_connections_leverage';
export const LOW_OPPORTUNITY_COST = 'low_opportunity_cost';
export const HIGH_ROI = 'high_roi';
export const FEEDBACK_LOOP = 'feedback_loop';
export const SKILL_AMPLIFICATION = 'skill_amplification';
export const AUTONOMY = 'autonomy';

export const TRAITS = [
    SCALABILITY,
    REUSABILITY,
    MULTIPLICATIVE_EFFECT,
    TIME_SENSITIVITY,
    STRATEGIC_IMPORTANCE,
    LABOR_LEVERAGE,
    CAPITAL_LEVERAGE,
    TECHNOLOGY_LEVERAGE,
    SOCIAL_CONNECTIONS_LEVERAGE,
    LOW_OPPORTUNITY_COST,
    HIGH_ROI,
    FEEDBACK_LOOP,
    SKILL_AMPLIFICATION,
    AUTONOMY,
];