import React from "react";
import { Typography, Divider, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardLayout from "../layouts/DashboardLayout";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '48px !important',
        fontWeight: 'bold',
        marginBottom: '24px',
    }
}));

const Dashboard = ({ children }) => {
    const classes = useStyles();

    return (
        <DashboardLayout>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1" className={classes.title}>Dashboard</Typography>
                        <Divider />
                        <Typography variant="body1" sx={{ marginTop: '24px' }}>Coming soon on the production site. Head over to "Tasks" to create and sort tasks for now.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    )
}

export default Dashboard;