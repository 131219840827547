import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children, isAllowed, redirectPath = '/login' }) => {
  console.log('Rendering ProtectedRoute');
  
  if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
  }


  return children ? children : <Outlet />;
};

export default ProtectedRoute;