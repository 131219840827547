import axios from 'axios';

import { getCurrentIdToken } from '../utils/aws';

import { TRAITS } from '../constants/traits';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getGoal = async (goalId) => {
    try {
        const response = await axios.get(`${baseURL}/goals/${goalId}`,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getGoals = async () => {
    try {
        const response = await axios.get(`${baseURL}/goals`,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createGoal = async (goal) => {
    try {
        const response = await axios.post(`${baseURL}/goals`, goal,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateGoal = async (goal) => {
    try {
        const response = await axios.put(`${baseURL}/goals`, goal,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
