import React from "react";
import { Typography, Grid, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Slider } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";

const useStyles = makeStyles(() => ({
    accordionContainer: {
        width: '100%',
        '& .MuiCollapse-root': {
            width: '100% !important',
        }
    },
    traitRankingReasonTextField: {
        display: 'block !important',
        '& .MuiInputBase-root': {
            width: '100%',
        }
    },
    traitFeedbackTextField: {
        display: 'block !important',
        '& .MuiInputBase-root': {
            width: '100%',
        }
    }
}));

const TaskTrait = ({ disabled, taskId, trait, value, reason, feedback, onChangeRanking, onChangeReason, onRerank }) => {
    const classes = useStyles();
    const [canRerank, setCanRerank] = React.useState(false);
    const [localRanking, setLocalRanking] = React.useState(value);
    const [localReason, setLocalReason] = React.useState(reason);
    const [localFeedback, setLocalFeedback] = React.useState(feedback);

    const debouncedOnChangeRanking = debounce((trait, newValue) => {
        onChangeRanking(trait, newValue);
    }, 500);

    const debouncedOnChangeReason = debounce((trait, newValue) => {
        onChangeReason(trait, newValue);
    }, 500);

    React.useEffect(() => {
        setCanRerank(localFeedback !== undefined && localFeedback !== '');
    }, [localFeedback]);

    const formatTraitName = (str) => {
        let formattedStr = str.replace(/_/g, ' '); // replace underscores with spaces
        formattedStr = formattedStr.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '); // capitalize first letter of each word
        return formattedStr;
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true, mountOnEnter: true }} className={classes.accordionContainer} disabled={disabled}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel-${taskId}-${trait}-content`}
                id={`panel-${taskId}-${trait}-header`}
                sx={{ backgroundColor: '#f5f5f5' }}
            >
                <Typography>{formatTraitName(trait)}: {value}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`panel-${taskId}-${trait}-content`} sx={{ position: 'relative' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Slider
                            id={`slider-${taskId}-${trait}-ranking`}
                            disabled={disabled}
                            name={trait}
                            value={localRanking}
                            onChange={(event, newValue) => {
                                setLocalRanking(newValue);
                                debouncedOnChangeRanking(event.target.name, newValue);
                            }}
                            aria-label="Small"
                            defaultValue={1}
                            min={1}
                            max={5}
                            step={1}
                            valueLabelDisplay="auto"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id={`outlined-multiline-static-${taskId}-${trait}-reason`}
                            name={trait}
                            disabled={disabled}
                            label="Reasoning"
                            multiline
                            rows={4}
                            value={localReason}
                            onChange={event => {
                                setLocalReason(event.target.value);
                                debouncedOnChangeReason(event.target.name, event.target.value);
                            }}
                            variant="outlined"
                            className={classes.traitRankingReasonTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id={`outlined-multiline-static-${taskId}-${trait}-feedback`}
                            name={trait}
                            disabled={disabled}
                            label="Feedback"
                            multiline
                            rows={4}
                            value={localFeedback}
                            onChange={event => {
                                setLocalFeedback(event.target.value);
                            }}
                            variant="outlined"
                            className={classes.traitFeedbackTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={disabled || !canRerank}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onChangeRanking(localRanking);
                                onRerank(trait, localFeedback);
                                setLocalFeedback('');
                            }}
                            className={classes.resubmitButton}
                        >
                            Re-Rank with Feedback
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
};

function propsAreEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value && prevProps.reason === nextProps.reason;
}

export default React.memo(TaskTrait, propsAreEqual);