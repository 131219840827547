import { Typography, Divider, Container, Grid, CircularProgress, Button, Modal, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import DashboardLayout from "../layouts/DashboardLayout";
import AddTaskModal from "../components/AddTaskModal";
import TaskCard from "../components/TaskCard";

import { getTasks, getTask, createTask, rankAllTaskTraits, updateTask } from "../api/tasks";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '48px !important',
        fontWeight: 'bold',
        marginBottom: '24px',
    },
    addButton: {
        marginLeft: 'auto !important',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '24px',
        borderRadius: '18px',
        minWidth: '500px'
    }
}));

function useTraceUpdate(props) {
    const prev = React.useRef(props);
    React.useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
            ps[k] = [prev.current[k], v];
        }
        return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

const TasksPage = (props) => {
    useTraceUpdate(props);
    const classes = useStyles();
    const [newTaskModalOpen, setNewTaskModalOpen] = React.useState(false);
    const [newTaskTitle, setNewTaskTitle] = React.useState('');
    
    // Fetching data
    const [loadingError, setLoadingError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [tasks, setTasks] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        let ignore = false;

        getTasks().then(tasks => {
            if (!ignore) {
                setTasks(tasks);
            }
        }).catch(error => {
            console.log(error);
            setLoadingError(error);
        }).finally(() => {
            setLoading(false);
        });

        return () => { ignore = true; };
    }, []);

    const refreshTasks = () => {
        setLoading(true);
        getTasks().then(tasks => {
            setTasks(tasks);
        }).catch(error => {
            console.log(error);
            setLoadingError(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleNewTaskModalOpen = () => { setNewTaskModalOpen(true); };
    const handleNewTaskModalClose = () => { setNewTaskModalOpen(false); };

    const handleCreateTask = async (taskTitle, taskDescription) => {
        // TODO: Send new task to backend for saving
        await createTask({ "task_title": taskTitle, "task_description": taskDescription });
        await refreshTasks();
        setNewTaskModalOpen(false);
    };

    const handleUpdateTask = async (updatedTask) => {
        console.log("🚀 ~ file: Tasks.jsx:102 ~ handleUpdateTask ~ updatedTask:", updatedTask);
        try {
            const savedTask = await updateTask(updatedTask);
            console.log("🚀 ~ file: Tasks.jsx:106 ~ handleUpdateTask ~ savedTask:", savedTask);
            let newTasks = tasks;
            const taskIndex = tasks.findIndex((t) => t.task_id === savedTask.task_id);
            if (taskIndex === -1) {
                newTasks = [...newTasks, savedTask];
            } else {
                newTasks[taskIndex] = savedTask;
            }
            setTasks([...newTasks]);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCreateAndAutoRankTask = async (taskTitle, taskDescription) => {
        let newTask = await createTask({ "task_title": taskTitle, "task_description": taskDescription });
        await rankAllTaskTraits(newTask.task_id);
        await refreshTasks();
        setNewTaskModalOpen(false);
    };

    const taskCards = React.useMemo(() => {
        const refreshTask = async (taskId) => {
            try {
                const task = await getTask(taskId);
                let newTasks = tasks;
                const taskIndex = tasks.findIndex((t) => t.task_id === taskId);
                if (taskIndex === -1) {
                    newTasks = [...newTasks, task];
                } else {
                    newTasks[taskIndex] = task;
                }
                setTasks([...newTasks]);
            } catch (error) {
                console.log(error);
            }
        };

        return tasks.map((task, index) => (
            <TaskCard
                key={task.task_id}
                task={task}
                updateTask={handleUpdateTask}
                refreshTask={refreshTask}
                style={{ marginTop: (index === 0) ? '18px' : '8px' }}
            />
        ));
    }, [tasks, handleUpdateTask]);

    // Console log to debug infinite render loop
    console.log('Rendering TasksPage');

    return (
        <DashboardLayout>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h1" className={classes.title}>Tasks</Typography>
                        <Button variant="contained" color="primary" className={classes.addButton} onClick={handleNewTaskModalOpen}>Add Task</Button>
                    </Grid>
                </Grid>
                
                <Divider sx={{ marginTop: '18px' }} />

                <Grid container spacing={3}>
                    {(loading || loadingError || (!loading && tasks && tasks.length === 0)) && (
                        <Grid item sx={{ marginTop: '12px' }} xs={12}>
                            {loading && <CircularProgress />}
                            {loadingError && <Typography variant="error">Error loading tasks: {loadingError}</Typography>}
                            {(!loading && tasks && tasks.length === 0) && <Typography variant="body1">No tasks yet! Add one above.</Typography>}
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        {(!loading && tasks && tasks.length > 0) && taskCards}
                    </Grid>
                </Grid>
            </Container>

            <AddTaskModal
                open={newTaskModalOpen}
                onClose={handleNewTaskModalClose}
                onCreateTask={handleCreateTask}
                onCreateAndAutoRankTask={handleCreateAndAutoRankTask}
            />
        </DashboardLayout>
    )
}

export default TasksPage;