import React, { useCallback } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, CircularProgress } from '@mui/material';
import { rankAllTaskTraits, rerankTraitWithFeedback } from '../api/tasks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { TRAITS } from '../constants/traits';
import TaskTrait from '../components/TaskTrait';

const useStyles = makeStyles(() => ({
    traitRankingReason: {
        width: '100%',
        fontSize: '14px !important',
        lineHeight: '20px !important',
    },
    taskLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) !important',
    },
    traitFeedback: {
        width: '100%',
        fontSize: '14px !important',
        lineHeight: '20px !important',
        marginTop: '10px !important',
        marginBottom: '16px !important',
    },
    accordionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        borderRadius: '8px !important',
        '& .MuiCollapse-root': {
            width: '100% !important',
        },
        '& > .Mui-expanded:first-child': {
            backgroundColor: '#f8f8f8 !important'
        },
        '&:before': {
            content: '""',
            border: 'none !important',
            backgroundColor: 'transparent !important',
        }
    },
    accordionSummary: {
        width: '100%',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        '& .MuiAccordionSummary-content': {
            display: 'flex !important',
            flexDirection: 'column !important',
            alignItems: 'flex-start !important',
            justifyContent: 'flex-start !important',
        },
        
        '&:hover': {
            backgroundColor: '#f8f8f8 !important',
        }
    },
    accordionDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
    },
    resubmitButton: {
        textTransform: 'capitalize !important',
        backgroundColor: '#3f51b5 !important',
        border: '1px solid #303f9f !important',
        color: '#fff !important',
        '&:hover': {
            backgroundColor: '#303f9f !important',
        },
    }
}));

function useTraceUpdate(props) {
    const prev = React.useRef(props);
    React.useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
            ps[k] = [prev.current[k], v];
        }
        return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

const TaskCard = ({ task, updateTask, refreshTask, style }) => {
    useTraceUpdate({ task, updateTask, refreshTask, style });
    const classes = useStyles();

    const [isMounted, setIsMounted] = React.useState(false);
    const [traitsBeingReranked, setTraitsBeingReranked] = React.useState({});

    // Update task rankings
    const [taskNeedsUpdate, setTaskNeedsUpdate] = React.useState(false);
    const [initialTraitRankings, setInitialTraitRankings] = React.useState({});
    const [rankingsNeedUpdate, setRankingsNeedUpdate] = React.useState(false);
    const [initialTraitReasons, setInitialTraitReasons] = React.useState({});
    const [reasonsNeedUpdate, setReasonsNeedUpdate] = React.useState(false);

    const [traitRankings, setTraitRankings] = React.useState({});
    const [traitReasons, setTraitReasons] = React.useState({});
    const [isAutoRanking, setIsAutoRanking] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        console.log('Rendering TaskCard');
    });

    React.useEffect(() => {
        if (task === undefined || task === null) return;

        const newTraitRankings = {};
        const newTraitReasons = {};
        const newTraitsBeingReranked = {};

        TRAITS.forEach((trait) => {
            newTraitRankings[trait] = task[`${trait}_ranking`];
            newTraitReasons[trait] = task[`${trait}_ranking_reason`];
            newTraitsBeingReranked[trait] = false;
        });

        setTraitsBeingReranked(newTraitsBeingReranked);
        setInitialTraitRankings(newTraitRankings);
        setInitialTraitReasons(newTraitReasons);
        setTraitRankings(newTraitRankings);
        setTraitReasons(newTraitReasons);
        setIsMounted(true);
    }, [task]);

    React.useEffect(() => {
        const detectRankingsNeedUpdate = () => {
            if (traitRankings === undefined || initialTraitRankings === undefined) return false;
            if (Object.keys(traitRankings).length !== Object.keys(initialTraitRankings).length) return false;
            for (const trait in traitRankings) {
                if (traitRankings[trait] !== initialTraitRankings[trait]) return true;
            }
            return false;
        }

        const detectReasonsNeedUpdate = () => {
            if (traitReasons === undefined || initialTraitReasons === undefined) return false;
            if (Object.keys(traitReasons).length !== Object.keys(initialTraitReasons).length) return false;
            for (const trait in traitReasons) {
                if (traitReasons[trait] !== initialTraitReasons[trait]) return true;
            }
            return false;
        }

        // if (traitRankings !== undefined && Object.keys(traitRankings).length > 0) console.log(`Trait Rankings: ${JSON.stringify(traitRankings)}`);
        // if (traitReasons !== undefined && Object.keys(traitReasons).length > 0) console.log(`Trait Reasons: ${JSON.stringify(traitReasons)}`);
        if (detectRankingsNeedUpdate() === true && rankingsNeedUpdate === false) setRankingsNeedUpdate(true);
        if (detectRankingsNeedUpdate() === false && rankingsNeedUpdate === true) setRankingsNeedUpdate(false);
        if (detectReasonsNeedUpdate() === true && reasonsNeedUpdate === false) setReasonsNeedUpdate(true);
        if (detectReasonsNeedUpdate() === false && reasonsNeedUpdate === true) setReasonsNeedUpdate(false);
    }, [traitRankings, traitReasons, initialTraitRankings, initialTraitReasons, rankingsNeedUpdate, reasonsNeedUpdate]);

    React.useEffect(() => {
        console.log(`Rankings need update: ${rankingsNeedUpdate}`);
        console.log(`Reasons need update: ${reasonsNeedUpdate}`);
        setTaskNeedsUpdate(rankingsNeedUpdate || reasonsNeedUpdate);
    }, [rankingsNeedUpdate, reasonsNeedUpdate]);

    const handleRankingChange = (trait, newValue) => {
        setTraitRankings(prevRankings => ({
            ...prevRankings,
            [trait]: newValue,
        }));
    }

    const handleReasonChange = (trait, newValue) => {
        setTraitReasons(prevReasons => ({
            ...prevReasons,
            [trait]: newValue,
        }));
    }

    const handleSaveChanges = useCallback(async () => {
        if (task === undefined || task === null) return;
        if (traitRankings === undefined || traitReasons === undefined) return;
        if (Object.keys(traitRankings).length !== Object.keys(traitReasons).length) return;
        
        setIsSaving(true);

        const newTask = {
            task_id: task.task_id,
            task_title: task.task_title ? task.task_title : '',
            task_description: task.task_description ? task.task_description : '',
            user_id: task.user_id ? task.user_id : null,
        }

        TRAITS.forEach((trait) => {
            newTask[`${trait}_ranking`] = traitRankings[trait];
            newTask[`${trait}_ranking_reason`] = traitReasons[trait];
        });

        await updateTask(newTask);

        setIsSaving(false);
    }, [task, traitRankings, traitReasons, updateTask]);

    const handleAutoRankTask = async (event) => {
        event.stopPropagation();
        setIsAutoRanking(true);
        try {
            await rankAllTaskTraits(task.task_id);
            await refreshTask(task.task_id);
        } catch (error) {
            console.log(error);
        } finally {
            setIsAutoRanking(false);
        }
    }

    const handleRerankTaskWithFeedback = async (trait, feedback) => {
        setTraitsBeingReranked(prevTraitsBeingReranked => ({
            ...prevTraitsBeingReranked,
            [trait]: true,
        }));

        try {
            const updatedRank = await rerankTraitWithFeedback(task.task_id, trait, feedback);
            handleReasonChange(trait, updatedRank['reason']);
            handleRankingChange(trait, updatedRank['ranking']);
        } catch (error) {
            console.log(error);
        } finally {
            setTraitsBeingReranked(prevTraitsBeingReranked => ({
                ...prevTraitsBeingReranked,
                [trait]: false,
            }));
        }
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={classes.accordionContainer}
            disabled={isAutoRanking}
            ref={containerRef}
            sx={{ ...style }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${task.task_id}-content`}
                id={`panel-${task.task_id}-header`}
                className={classes.accordionSummary}
            >
                <Typography variant="body1" gutterBottom>{task.task_title}</Typography>
                {task.task_description && <Typography variant="body2" gutterBottom><strong>Description:</strong> {task.task_description}</Typography>}
                {task.score && task.score !== 0 && (<Typography gutterBottom><span style={{ fontWeight: 'bold' }}>Score: </span>{task.score.toFixed(1)}</Typography>)}
            </AccordionSummary>
            <AccordionDetails id={`panel-${task.task_id}-content`} className={classes.accordionDetails}>
                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'flex-start', width: '100%', marginBottom: '24px',
                    marginTop: '12px',
                }}>
                    <Button variant="contained" size="small" disabled={isAutoRanking} onClick={handleAutoRankTask}>Auto Rank Task</Button>
                    <Button variant="contained" size="small" disabled={isAutoRanking} color="error" sx={{ marginLeft: '12px' }} onClick={() => { alert('Functionality coming soon.') }}>Delete Task</Button>
                    <Button variant="text" size="small" disabled={!taskNeedsUpdate} sx={{ marginLeft: '12px' }} onClick={handleSaveChanges}>Save Changes</Button>
                </div>
                {isMounted && TRAITS.map(trait => {
                    const isReranking = traitsBeingReranked[trait];
                    return (<TaskTrait
                        key={`${task.task_id}_${trait}`}
                        disabled={isAutoRanking || isSaving || isReranking}
                        taskId={task.task_id}
                        trait={trait}
                        value={traitRankings && traitRankings[trait] ? traitRankings[trait] : 1}
                        reason={traitReasons && traitReasons[trait] ? traitReasons[trait] : ''}
                        onChangeRanking={handleRankingChange}
                        onChangeReason={handleReasonChange}
                        onRerank={handleRerankTaskWithFeedback}
                    />);
                })}
            </AccordionDetails>
            {(isAutoRanking || isSaving) && <CircularProgress className={classes.taskLoader} />}
        </Accordion>
  );
};

export default React.memo(TaskCard);