import { Typography, Button, Modal, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '48px !important',
        fontWeight: 'bold',
        marginBottom: '24px',
    },
    addButton: {
        marginLeft: 'auto !important',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.35)',
        padding: theme.spacing(3),
        borderRadius: '18px',
        position: 'relative'
    },
    taskTitleTextField: {
        marginBottom: '24px',
    },
    taskDescriptionTextField: {
        marginBottom: '24px',
    },
    closeButton: {
        position: 'absolute !important',
        top: '12px',
        right: '12px',
        borderRadius: '50% !important',
        width: '36px !important',
        height: '36px !important',
        padding: '0px !important',
        minWidth: 'none !important',
        color: '#2a2a2a !important',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }
}));

const AddTaskModal = ({ open, onClose, onCreateTask, onCreateAndAutoRankTask }) => {
    const classes = useStyles();
    const [newTaskTitle, setNewTaskTitle] = React.useState('');
    const [newTaskDescription, setNewTaskDescription] = React.useState('');
    const [actionButtonsEnabled, setActionButtonsEnabled] = React.useState(false);

    React.useEffect(() => {
        setActionButtonsEnabled(newTaskTitle.length > 0);
    }, [newTaskTitle, newTaskDescription]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-task-modal-title"
            aria-describedby="add-task-modal-description"
            className={classes.modal}
        >
            <Grid container className={classes.paper} maxWidth="md" spacing={0}>
                <Button className={classes.closeButton} onClick={() => {
                    setNewTaskTitle('');
                    setNewTaskDescription('');
                    onClose();
                }}><Close /></Button>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontSize: '24px' }} id="add-task-modal-title" gutterBottom>
                        Add a New Task
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '12px' }}>
                    <TextField
                        fullWidth
                        label="Task Title"
                        variant="outlined"
                        className={classes.taskTitleTextField}
                        value={newTaskTitle}
                        onChange={(event) => setNewTaskTitle(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Task Description"
                        variant="outlined"
                        className={classes.taskDescriptionTextField}
                        value={newTaskDescription}
                        onChange={(event) => setNewTaskDescription(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-start" className={classes.buttonContainer}>
                    <Button variant="contained" disabled={!actionButtonsEnabled} color="primary" className={classes.button} onClick={() => { onCreateTask(newTaskTitle, newTaskDescription); }}>Create Task</Button>
                    <Button variant="contained" disabled={!actionButtonsEnabled} color="secondary" onClick={() => { onCreateAndAutoRankTask(newTaskTitle, newTaskDescription); }} sx={{ marginLeft: '8px' }}>Save and Auto-Rank Traits</Button>
                    <Button variant="text" color="warning" onClick={() => {
                        setNewTaskTitle('');
                        setNewTaskDescription('');
                        onClose();
                    }} sx={{ marginLeft: '8px' }}>Cancel</Button>
                </Grid>
                
            </Grid>
        </Modal>
    )
};

export default AddTaskModal;