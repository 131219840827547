import axios from 'axios';

import { getCurrentIdToken } from '../utils/aws';

import { TRAITS } from '../constants/traits';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getTask = async (taskId) => {
    try {
        const response = await axios.get(`${baseURL}/tasks/${taskId}`,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getTasks = async () => {
    try {
        const response = await axios.get(`${baseURL}/tasks`,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createTask = async (task) => {
    try {
        const response = await axios.post(`${baseURL}/tasks`, task,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const updateTask = async (task) => {
    try {
        const response = await axios.put(`${baseURL}/tasks`, task,
            {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const rerankTraitWithFeedback = async (taskId, traitKey, feedback) => {
    try {
        const response = await axios.post(`${baseURL}/tasks/rerank`, {
            'task_id': taskId,
            'trait_key': traitKey,
            'feedback': feedback
        }, {
            headers: {
                Authorization: await getCurrentIdToken()
            }
        });

        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const rankAllTaskTraits = async (taskId) => {
    const traitRankings = {};

    try {
        await Promise.all(TRAITS.map(async (trait) => { 
            const response = await axios.post(`${baseURL}/tasks/rank-trait`, {
                'task_id': taskId,
                'trait_key': trait
            }, {
                headers: {
                    Authorization: await getCurrentIdToken()
                }
            });

            traitRankings[trait] = response.data;
        }));
        
        return traitRankings;
    } catch (error) {
        console.error(error);
        return null;
    }
}