import React from "react";
import { Typography, Divider, Container, Grid, TextField, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardLayout from "../layouts/DashboardLayout";
import { getGoals, createGoal } from "../api/goals";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '48px !important',
        marginBottom: '24px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '36px !important',
            marginBottom: '18px !important',
        }
    },
    subsection: {
        backgroundColor: '#fefefe',
        padding: '24px',
        borderRadius: '18px',
        marginTop: '24px !important',
        marginBottom: '24px !important',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('sm')]: {
            padding: '16px',
        }
    },
    subsectionTitle: {
        fontSize: '28px !important',
        marginBottom: '8px !important',
    }
}));

const SettingsPage = ({ children }) => {
    const classes = useStyles();

    const [loadingUserData, setLoadingUserData] = React.useState(false);
    const [loadingError, setLoadingError] = React.useState('');
    const [addingGoal, setAddingGoal] = React.useState(false);

    const [newUserGoal, setNewUserGoal] = React.useState('');
    const [userGoals, setUserGoals] = React.useState([]);

    React.useEffect(() => {
        setLoadingUserData(true);
        getGoals().then((goals) => {
            setUserGoals(goals);
        }).catch((error) => {
            console.error(error);
            setLoadingError(error);
        }).finally(() => {
            setLoadingUserData(false);
        });
    }, []);

    const handleAddGoal = async () => {
        setAddingGoal(true);
        try {
            const newGoal = await createGoal({ title: newUserGoal });
            setUserGoals([...userGoals, newGoal]);
            setNewUserGoal('');
        } catch (error) {
            console.error(error);
            setLoadingError(error);
        } finally {
            setAddingGoal(false);
        }
    }

    return (
        <DashboardLayout>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1" className={classes.title}>Settings</Typography>
                        <Divider />
                    </Grid>
                    {(loadingUserData === true) ? (
                        <Grid item xs={12} sx={{ marginTop: '32px' }}>
                            <Typography variant="h3" className={classes.subsectionTitle}>Loading...</Typography>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} className={classes.subsection}>
                                <Typography variant="h3" className={classes.subsectionTitle}>Account</Typography>
                                <Typography variant="body1">Coming soon!</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.subsection}>
                                <Typography variant="h3" className={classes.subsectionTitle}>Your Goals</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>Enter your wealth generation goals below. These will be used to help you rank your tasks.</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            disabled={addingGoal}
                                            id="new-user-goal"
                                            variant="outlined"
                                            margin="normal"
                                            value={newUserGoal}
                                            onChange={(event) => { setNewUserGoal(event.target.value) }}
                                            label="New Goal"
                                            name="new-learning-goal"
                                            autoComplete="off"
                                        />
                                        {addingGoal && (
                                            <CircularProgress sx={{ display: 'block' }} />
                                        )}
                                        <Button
                                            type="submit"
                                            disabled={loadingUserData || newUserGoal === '' || addingGoal}
                                            variant="contained"
                                            sx={{ marginTop: '16px' }}
                                            onClick={handleAddGoal}
                                        >
                                            Add Learning Goal
                                        </Button>
                                        <Divider sx={{ marginTop: '16px' }} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: '16px' }}>
                                        {(userGoals.length === 0) && (
                                            <Typography variant="body1">You have no learning goals. Please add some above.</Typography>        
                                        )}
                                        {(userGoals && userGoals.length > 0) && (
                                            <>
                                                <Typography variant="body1" sx={{ marginTop: '16px' }}>Your current learning goals:</Typography>
                                                <ul style={{ marginTop: '8px', listStyleType: 'disc', paddingLeft: '24px' }}>
                                                    {userGoals.map((goal) => (
                                                        <li key={goal.goal_id}><Typography variant="body1">{goal.title}</Typography></li>
                                                    ))}
                                                </ul>
                                            </>        
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    
                </Grid>
            </Container>
        </DashboardLayout>
    )
}

export default SettingsPage;