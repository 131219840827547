import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { useTheme, ThemeProvider } from '@mui/material/styles';

// Components and pages
import ProtectedRoute from './components/ProtectedRoute';
import SettingsPage from './pages/Settings';
import Dashboard from './pages/Dashboard';
import Homepage from './pages/Homepage';
import TasksPage from './pages/Tasks';
import LoginPage from './pages/Login';

// Utils
import { getUserPool } from './utils/aws';

function App() {
  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    const refreshAuthentication = () => {
      const userPool = getUserPool();
      const cognitoUser = userPool.getCurrentUser();
  
      if (cognitoUser == null || cognitoUser === undefined) {
        if (authenticated === true) { setAuthenticated(false); }
        return;
      }
      
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error(err);
          if (authenticated === true) { setAuthenticated(false); }
          return;
        }
        // Check if the session is valid.
        if (session.isValid()) {
          localStorage.setItem('idToken', session.getIdToken().getJwtToken());
          localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
          localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
          if (authenticated === false) { setAuthenticated(true); }
        } else {
          cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
              console.error(err);
              if (authenticated === true) { setAuthenticated(false); }
              return;
            }
            localStorage.setItem('idToken', session.getIdToken().getJwtToken());
            localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
            localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
            if (authenticated === false) { setAuthenticated(true); }
          });
        }
      });
    }

    refreshAuthentication();
  }, [authenticated]);
  
  return (
    <ThemeProvider theme={useTheme()}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoute isAllowed={authenticated} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tasks" element={<TasksPage />} />
              <Route path="/settings" element={<SettingsPage />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
