import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js';

export const getUserPool = () => {
    const poolData = {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
    };

    return new CognitoUserPool(poolData);
}

export const getCurrentIdToken = () => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) { reject(err); }
                resolve(session.getIdToken().getJwtToken());
            });
        });
    } else {
        return null;
    }
}

export const getCurrentAccessToken = () => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) { reject(err); }
                resolve(session.getAccessToken().getJwtToken());
            });
        });
    } else {
        return null;
    }
}

export const refreshCurrentSession = () => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) { reject(err); }
                cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
                    if (err) { reject(err); }
                    resolve(session);
                });
            });
        });
    } else {
        return null;
    }
}

export const signUp = (email, password) => {
    const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];
    
    const userPool = getUserPool();

    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, attributeList, null, (err, result) => {
            if (err) { reject(err); }
            const cognitoUser = result.user;
            cognitoUser.getAttributeVerificationCode('email', {
                onSuccess: () => resolve(cognitoUser),
                onFailure: err => reject(err)
            });
        });
    });
}

export const signIn = (email, password) => {
    const userPool = getUserPool();
    const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password
    });

    const userData = {
        Username: email,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: result => resolve(result),
            onFailure: err => reject(err)
        });
    });
}

export const signOut = () => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
        cognitoUser.signOut();
    }
}

export const verifyEmail = (email, code) => {
    const userPool = getUserPool();
    const userData = {
        Username: email,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err);
            }
            resolve(result);
        });
    });
}