import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { spacing } from '@mui/system';
import {
    AppBar, Toolbar, Typography,
    Button, Container, Grid, Box, Paper
} from "@mui/material";

import { getCurrentIdToken, signIn } from "../utils/aws";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.2)',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    logo: {
        flexGrow: 1,
    },
    heroTitle: {
        textShadow: '0px 2px 6px rgba(0, 0, 0, 0.7)',
    },
    heroSubtitle: {
        textShadow: '0px 2px 6px rgba(0, 0, 0, 0.7)',
    },
    hero: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        textAlign: 'center',
    },
    demo: {
        padding: spacing(8, 0),
    },
    cta: {
        backgroundColor: '#f50057',
        color: '#fff',
        padding: spacing(6),
        textAlign: 'center',
    },
}));

const Homepage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        fetchToken();
    }, []);

    const fetchToken = async () => {
        const token = await getCurrentIdToken();
        setIsLoggedIn(token !== null);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.logo}>LeverageGPT</Typography>
                    {isLoggedIn && (
                        <Button color="inherit" component={Link} to="/dashboard">Dashboard</Button>
                    )}
                    <Button color="inherit">{isLoggedIn ? 'Log out' : 'Login'}</Button>
                </Toolbar>
            </AppBar>
            <Box className={classes.hero}>
                <Container maxWidth="md">
                    <Typography variant="h2" className={classes.heroTitle} component="h1" gutterBottom>Automate High Leverage</Typography>
                    <Typography variant="h5" className={classes.heroSubtitle} component="p" gutterBottom>A modern tool for developers</Typography>
                </Container>
            </Box>
            <Box className={classes.demo}>
                <Container maxWidth="lg">
                    <Typography variant="h4" component="h2" gutterBottom>
                        Check out some demos
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="h5" component="h3">
                                Demo 1
                                </Typography>
                                <Typography component="p">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="h5" component="h3">
                                Demo 2
                                </Typography>
                                <Typography component="p">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper className={classes.paper}>
                                <Typography variant="h5" component="h3">
                                Demo 3
                                </Typography>
                                <Typography component="p">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.cta}>
                <Container maxWidth="md">
                    <Typography variant="h4" component="h2" gutterBottom>
                        Ready to get started?
                    </Typography>
                    <Button variant="contained" size="large">
                        Register Now
                    </Button>
                </Container>
            </Box>
        </div>
    );
}

export default Homepage;