import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Drawer, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { signOut } from "../utils/aws";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            paddingTop: '48px !important',
            paddingBottom: '32px !important',
            backgroundColor: '#f4f6f8 !important',
        },
    },
    drawer: {
        width: '240px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '240px',
            boxSizing: 'border-box',
            backgroundColor: '#f4f6f8',
            color: '#333',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '& .MuiDrawer-paper': {
                width: '100%',
                boxSizing: 'border-box',
            },
        }
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '24px',
        backgroundColor: '#f4f6f8',
        minHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            padding: '16px',
        }
    },
    menuButton: {
        position: 'fixed !important',
        backgroundColor: '#fff !important',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        top: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        color: '#333',
    },
    closeButton: {
        position: 'absolute',
        justifyContent: 'flex-end !important',
        width: 'fit-content !important',
        marginLeft: 'auto !important',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: '#333',
    },
    listItem: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    listItemText: {
        color: '#333',
    },
    logoutButton: {
        marginTop: 'auto',
    },
}));

const DashboardLayout = ({ children }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) { 
            console.log(`Error signing out: ${error}`);
        }
    }

    const toggleDrawer = () => { setIsDrawerOpen(!isDrawerOpen) };

    return (
        <Box className={classes.root}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                className={classes.menuButton}
            >
                <Menu className={classes.menuIcon} />
            </IconButton>
            <Drawer variant="temporary" open={isDrawerOpen} onClose={toggleDrawer} className={classes.drawer}>
                <IconButton className={classes.closeButton} onClick={toggleDrawer}>
                    <Close />
                </IconButton>
                <List>
                    {['Dashboard', 'Tasks', 'Settings'].map((text, index) => (
                        <ListItem button key={text} component={Link} to={`/${text.toLowerCase()}`}>
                            <ListItemText primary={text} className={classes.listItemText} />
                        </ListItem>
                    ))}
                    <ListItem button onClick={handleLogout}>
                        <ListItemText primary="Logout" className={classes.listItemText} />
                    </ListItem>
                </List>
            </Drawer>
            <Box className={classes.body}>
                {children}
            </Box>
        </Box>
    )
}

export default DashboardLayout;