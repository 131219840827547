import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { signIn } from "../utils/aws";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        position: 'relative'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: '24px',
        backgroundColor: '#f5f5f5',
        borderRadius: 1,
        position: 'relative',
        borderRadius: '24px',
        // Extremely soft shadow
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    logo: {
        width: 100,
        height: 100,
        borderRadius: '50%',
        position: 'absolute',
        top: -50
    },
    title: {
        marginTop: '36px !important',
        marginBottom: '16px !important',
        fontSize: '24px !important',
    },
    textField: {
        width: '100%',
        backgroundColor: '#fff',
        minWidth: '350px !important',
        color: '#000',
        marginBottom: '8px !important',
    },
    loginButton: {
        width: '100%',
        marginBottom: '12px !important',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#3f51b5 !important', // Change the color to make the button pop out more
        color: '#fff !important', // Change the text color to white
        '&:hover': {
            backgroundColor: '#303f9f !important', // Add a hover effect
        },
    },
    signupLink: {
        textTransform: 'none !important',
        fontSize: '14px !important',
        marginBottom: '0px !important',
        color: '#3f51b5 !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    loginError: {
        marginBottom: '12px !important'
    }
}));

const LoginPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [loginError, setLoginError] = React.useState(null);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoginError(null);
        const { email, password } = event.target.elements;
        try {
            const { idToken, accessToken, refreshToken } = await signIn(email.value, password.value);
            console.log("🚀 ~ file: Login.jsx:83 ~ handleLogin ~ refreshToken:", refreshToken);
            console.log("🚀 ~ file: Login.jsx:83 ~ handleLogin ~ accessToken:", accessToken);
            console.log("🚀 ~ file: Login.jsx:83 ~ handleLogin ~ idToken:", idToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
            navigate('/dashboard');
        } catch (error) {
            setLoginError(error.message);
            console.log(`Error signing in: ${error}`);
        }
    };

    return (
        <Box className={classes.root}>
            <Box component="form" onSubmit={handleLogin} className={classes.form}>
                <Box component="img" src="https://e7.pngegg.com/pngimages/581/455/png-clipart-insane-productivity-for-lazy-people-a-complete-system-for-becoming-incredibly-productive-e-book-digitalization-logo-maintenance.png" alt="logo" className={classes.logo} />
                <Typography variant="h4" className={classes.title}>Login to LeverageGPT</Typography>
                <TextField label="Email" name="email" type="email" className={classes.textField} required />
                <TextField label="Password" name="password" type="password" className={classes.textField} required />
                {loginError && <Typography color="error" className={classes.loginError}>{loginError}</Typography>}
                <Button type="submit" className={classes.loginButton}>Login</Button>
                <Button onClick={() => navigate('/signup')} variant="text" className={classes.signupLink}>Don't have an account? Sign up</Button>
            </Box>
        </Box>
    )
}

export default LoginPage;